import React from "react";
import FormSignin from "@/components/Form/Signin";
import Survey from "@/components/Services/Survey/Form/IncidentOpen";
import { Logo, Link } from "@scanow/ui";
import { APP_URL, APP_MODULE } from "config";
import { APP_NAME, APP_VERSION } from "@scanow/config";

interface ISignin {
  title?: string;
  className?: string;
}

export default function SigninComponent({ title, className = "" }: ISignin) {
  return (
    <section>
      <div className="max-w-md mx-auto space-y-6">
        <div className="grid gap-3">
        <div className="mx-auto">
            <Logo />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <span className="border-b border-neutral-900 dark:border-neutral-700 w-1/5 lg:w-1/4"></span>
          <Link
            to={APP_URL}
            className="text-xs text-center text-neutral-900 dark:text-neutral-700 uppercase"
          >
            {APP_NAME + " " + APP_MODULE}{" "}
            {<small className="text-font-light">{APP_VERSION}</small>}
          </Link>
          <span className="border-b border-neutral-900 dark:border-neutral-700 w-1/5 lg:w-1/4"></span>
        </div>
        <FormSignin />
        {/*<Survey />*/}
      </div>
    </section>
  );
}
