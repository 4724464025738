import { useEffect, useState } from "react";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import userAuth from "@/helpers/auth";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Layout from "@/components/Layout";
import { Container, Title } from "@scanow/ui";
import SigninForm from "@/components/Auth/Signin";
import Seo from "@/components/Shared/Seo";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const HomePage: NextPage = () => {

  const { t } = useTranslation("home");
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const router = useRouter();
  const auth = userAuth();
  const pageImage = "/images/test.png";

  useEffect(() => {
    if (auth.isLogged) {
      if (auth.sonar_role_weight <= 3) {
        router.push("/admin/dashboard");
      } else {
        toast.warning(
          "L'accès à cette partie de l'application est restreinte, veuillez contacter votre administrateur si vous pensez que c'est une erreur."
        );
        removeCookie("accessToken", { path: "/" });
      }
    } 
  }, []);

  return (
    <Layout layout="public">
      <Seo
        // pageTitle={t("title")}
        pageTitle="Scanow Helpdesk identification"
        pageDescription="Gagnez en productivité et en performance au sein de votre service client"
        pageImage={pageImage}
      />
      <Container appendClassName="h-screen pt-20">
        <SigninForm />
      </Container>
    </Layout>
  );
}

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "common",
      "navigation",
      "home",
      "footer",
    ])),
  },
});

export default HomePage;
